import React, { Component } from 'react';
import 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js';
import Chart from 'react-google-charts';
import { generateExcelFile } from '../utill/download';
import api from '../config.json';
import swal from 'sweetalert';
import axios from '../httpServices/httpService';
import Loading from '../utill/LoadingComponent';
import validate from '../utill/validation';
import joi from 'joi'
import Table from '../Common/table';
import EquipmentSelection from '../Common/equipmentSelection';
import MultiSelectCheckBox from '../Common/MultiSelectCheckBox';
import { ToastContainer, toast } from 'react-toastify';

import auth from '../httpServices/authService';
import DateSelection from '../Common/DateSelection';


class DowntimePareto extends Component {
  state = {
    shiftOption: [
      { label: 'Shift1', value: 'Shift1', checked: false },
      { label: 'Shift2', value: 'Shift2', checked: false },
      { label: 'Shift3', value: 'Shift3', checked: false },
      { label: 'Shift4', value: 'Shift4', checked: false }],
      downtime:[
        { label:'planned',value:'planned',checked:false},
        { label:'unplanned',value:'unplanned',checked:false},],
    myNewChart: '',
    viewBarChartOnClick: false,
    filterBy: '',
    barColorStateWise: 'red',
    downtimeParetoOrganiseData: [],
    downtimeParetoData: [],
    downtimeReasons: [],
    shiftSetting: [],
    Bardata: [
      ["Machine", "Time(In Mins)", { role: "style" }],

    ],

    inputData: {},
    errors: {},
    downtimeDataCumulative: [],
    querySuccess: false,
    viewBarChartOnClick: false,
    selectedEquipGroupOption: [],
    equip: [],
    selectedEquipOption: []

  }
  Bardata = [
    ["Machine", "Time(In Mins)", { role: "style" }],

  ];

  setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
      this.setState({ equip: equips ? equips : [], selectedEquipGroupOption: option, selectedEquipOption: [] })
    }
    if (key == 'Equipment') {

      this.setState({ selectedEquipOption: option })
    }
  }

  getEquipsForReport = () => {
    let equipArr = []
    this.props.equips.forEach((one) => {
      this.state.selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      })
    })
    return equipArr;
  }

  schema = {

    UserId: joi.string(),

    Equips: joi.array(),
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    Shift: joi.array().min(1).required().label('please select at least one shift'),
    Downtime: joi.array(),
    //Downtime: joi.array().min(1).required()
    //Shift: joi.string().required().label('Shift')
  }

  columns = [
    { path: 'ReasonGroup', label: 'Reason Group', content: (obj) => <label>{( obj.Event[0].DowntimeGroup === null ?'Down':obj.Event[0].DowntimeGroup )}</label> },
    { path: 'State', label: 'Reason' },
    { path: 'Duration', label: 'Duration (In Mins)', content: (obj) => <label>{((obj.Duration / (60)).toFixed(1))}</label> },
    { path: 'OriginalPercentage', label: 'Individual Percentage', content: (obj) => <label>{(parseFloat(obj.OriginalPercentage)).toFixed(1)}</label> },
    { path: 'Percentage', label: 'Cumulative Percentage', content: (obj) => <label>{(parseFloat(obj.Percentage)).toFixed(1)}</label> },
  ]





  handleDownloadExcel = (e) => {
    generateExcelFile(e)
  }

  handleOnChange = async (e) => {
    const { inputData, errors } = { ...this.state }
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    }
    else {
      if (e.currentTarget.name == 'Shift') {
        const options = [...this.state.shiftOption];
        if (e.currentTarget.value && (e.currentTarget.value == 'All')) {
          options.forEach(one => one.checked = e.currentTarget.checked);
        } else {
          options.forEach(one => {
            if (one.value == e.currentTarget.value) {
              one.checked = e.currentTarget.checked
            }
          });
        }
        inputData[e.currentTarget.name] = options.filter(one => one.checked);
        this.setState({ shiftOption: options });
      }
      else if(e.currentTarget.name=='Downtime'){
        const options=[...this.state.downtime];
        if(e.currentTarget.value&&(e.currentTarget.value=='All')){
          options.forEach(one=>one.checked=e.currentTarget.checked);
        }else{
          options.forEach(one=>{
            if(one.value==e.currentTarget.value){
              one.checked=e.currentTarget.checked
            }
          });
        }
        inputData[e.currentTarget.name] = options.filter(one=>one.checked === true);
        this.setState({downtime:options});
      } else {
        inputData[e.currentTarget.name] = e.currentTarget.value;
      }
    }
    let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
    this.setState({ inputData: inputData, errors: errors || {}, shiftSetting: res.data })
  }


  handleOnSubmit = (inputData) => {
    inputData = { ...inputData }

    const errors = validate.validate(inputData, this.schema);

    this.setState({ errors: errors || {} })
    if (errors) return;
    this.setState({ querySuccess: true })

    inputData.UserId = this.props.user._id
  

    if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else {
      this.setState({ querySuccess: false })
      return toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    inputData.Equips = this.getEquipsForReport()
    inputData.Shift = inputData.Shift.map(one => one.value);
    axios.post(api.api + 'GetDowntimePareto', inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          let data = this.organiseParetoData(result.data)
          this.setState({ querySuccess: false, downtimeParetoOrganiseData: data, downtimeParetoData: result.data });

        }
        else {
          swal('Data Not Available');
          this.setState({ querySuccess: false })
        }
      }
      else {
        this.setState({ querySuccess: false })
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        this.setState({ querySuccess: false })
      })

  }

  organiseParetoData = (clone) => {
    let data = [...clone];
    let downTimeParetoArray = [];
    let schema = ['State', 'Time( in minutes)', { role: 'annotation' }, { role: 'style' }, 'Percentage(%)', { role: 'annotation' }];
    downTimeParetoArray.push(schema);
    //    if (this.props.reasonSearchText.length > 0) {
    //      data = data.filter(reason => { return reason.Reason.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
    //      for (let i = 0; i < data.length; i++) {
    //        let StateArray = [];
    //        if (data[i].Reason !== "OK") {
    //          StateArray.push(data[i].Reason);
    //          StateArray.push(data[i].Duration);
    //          StateArray.push(data[i].Color.toLowerCase());
    //          StateArray.push(data[i].Percentage);
    //          // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
    //          // StateArray.push(accumPer);
    //          downTimeParetoArray.push(StateArray);
    //        }
    //      }
    //    }
    //    else
    //    {
    for (let i = 0; i < data.length; i++) {
      let StateArray = [];
      if (data[i].Reason !== "OK") {
        StateArray.push(data[i].State);
        StateArray.push(parseFloat((data[i].Duration / (60)).toFixed(1)));
        StateArray.push(parseFloat((data[i].Duration / (60)).toFixed(1)));

        StateArray.push(data[i].Color);
        StateArray.push(parseFloat((data[i].Percentage).toFixed(1)));
        StateArray.push(parseFloat((data[i].Percentage).toFixed(1)));

        // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
        // StateArray.push(accumPer);
        downTimeParetoArray.push(StateArray);
      }
    }
    // }
    // consol(data);
    let accumPer = 0;
    return downTimeParetoArray;
  }


  renderParetoChart = (data) => {
    return <Chart
      width={'1000px'}
      height={'500px'}
      chartType="ComboChart"
      loader={<div>Loading Chart</div>}
      data={data}
      options={{
        backgroundColor: { strokeWidth: 2 },
        // title: this.props.downTimeParetoText,
        legend:"none",
        annotations: {
          style: "line",
          role: "annotation",
          alwaysOutside: false,
          stem: {
            color: "transparent",
            length: 0, // Adjust the length of the annotation line
          },
          textStyle: {
            fontSize: 12,
          },
        },
        vAxes: [
          { title: 'Time( in minutes)', minValue: 0 },
          { title: " Cumulative Percentage", minValue: 0, maxValue: 100 }
        ],

        hAxis: { title: 'Reason', slantedTextAngle: 90, slantedText: true },
        seriesType: 'bars',
        series: { 1: { type: "line", targetAxisIndex: 1 } },
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  }
  SelectedDates =(dates)=>{
    this.setState({inputData:dates})
  }



  render() {

    return (
      <div style={{ marginTop: 30 }} className='justify-content-center'>
        {this.state.querySuccess ? <Loading /> : ''}
        <div className="text-center heading-bg mb-4">
          <h4 className="text-white p-0 m-0" >
            Downtime Pareto
          </h4>
        </div>
        <ToastContainer/>
        <div className='row'>
       { this.props.equipGroups.length > 0 &&  <EquipmentSelection equipGroups={this.props.equipGroups} equip={this.state.equip} selectedEquipGroupOption={this.state.selectedEquipGroupOption} selectedEquipOption={this.state.selectedEquipOption} setSelection={this.setSelection} />}
          {/* <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>From</label>
              <input className={'form-control form-control-sm'} min={this.props.fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={this.handleOnChange} name='From' />
              {this.state.errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.From}</p>}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Till</label>
              <input className={'form-control form-control-sm'} placeholder='dd/mm/yyyy' type='date' onChange={this.handleOnChange} name='Till' />
              {this.state.errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Till}</p>}
            </div>
          </div> */}
           <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Downtime Type</label>
              <MultiSelectCheckBox name='Downtime' onChange={this.handleOnChange} selectedValues={this.state.inputData['Downtime']} options={this.state.downtime} />
              {this.state.errors.Downtime && <p className='text-danger ' style={{ fontSize: 11 }}>please select Downtime type</p>}
            </div>
          </div>
        <DateSelection SelectedDates={this.SelectedDates} fromDateLimit={this.props.fromDateLimit} />

          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Shift</label>
              <MultiSelectCheckBox name='Shift' onChange={this.handleOnChange} selectedValues={this.state.inputData['Shift']} options={this.state.shiftOption} />
              {this.state.errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Shift}</p>}
            </div>
          </div>
          {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={this.handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              {this.state.shiftSetting.map(one=>{
                
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {this.state.errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Shift}</p>}
          </div>
        </div> */}

          <div className="col-sm-1">
            <button className='btn btn-primary btn-sm mt-4' onClick={() => this.handleOnSubmit(this.state.inputData)}>Apply</button>
          </div>
        </div>
        {this.state.downtimeParetoOrganiseData.length > 0 ? <div className='row ml-2'>
          {this.renderParetoChart(this.state.downtimeParetoOrganiseData)}

        </div> : ''}

        {this.state.downtimeParetoData.length > 0 ? <div className="col-sm-7 table-responsive-sm table-striped" >
          <p className='btn btn-primary btn-sm' onClick={() => this.handleDownloadExcel('DowntimePareto')}>Download Excel</p>
          <Table columns={this.columns} id='DowntimePareto' data={this.state.downtimeParetoData} sortColumn={{ path: 'Reason', order: 'asc' }} />
        </div > : ''}



      </div>

    );
  }
}

// function getip(json) {
//     alert( json.ip);
//     }


export default DowntimePareto;